<template>
  <div class="bg-neutral-100 p-12">
    <div class="container grid grid-cols-5 justify-center items-start gap-x-8">
      <div class="col-span-2 items-start flex flex-col space-y-2">
        <img src="~/assets/images/biogena-gray-logo.svg" class="w-48 h-auto my-4" />
        <div class="bg-white inline-flex items-center rounded-md py-2 ltr:pl-4 ltr:pr-8 rtl:pl-8 rtl:pr-4">
          <div class="h-16 w-16 rounded-full bg-neutral-100 flex items-center justify-center ltr:mr-4 rtl:ml-4">
            <span class="text-3xl text-primary-1000 font-bold">?</span>
          </div>
          <div class="flex flex-col">
            <NuxtLink :to="generateLink('/contact')">
              <div class="text-lg text-primary-1000 font-bold uppercase">{{ t('footer.help') }}</div>
              <div class="text-primary-1000">{{ t('footer.contact') }}</div>
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="col-span-1 flex flex-col space-y-2">
        <div class="text-xl text-primary-1000 pb-2 font-bold">{{ t('nav.shop') }}</div>
        <NuxtLink :to="generateLink(category.slug)" class="text-primary-1000" v-for="category in shopMenu?.subItems">{{
          category.title
        }}</NuxtLink>
      </div>
      <div class="col-span-1 flex flex-col space-y-2">
        <div class="text-xl text-primary-1000 pb-2 font-bold">{{ t('footer.about') }}</div>
        <NuxtLink :to="generateLink('/why-biogena')" class="text-primary-1000">{{ t('nav.about') }}</NuxtLink>
        <NuxtLink :to="generateLink('/faq')" class="text-primary-1000">{{ t('footer.faq') }}</NuxtLink>
        <NuxtLink :to="generateLink('/warnings')" class="text-primary-1000">{{ t('footer.warnings') }}</NuxtLink>
      </div>
      <div class="col-span-1 flex flex-col space-y-2">
        <div class="text-xl text-primary-1000 pb-2 font-bold">{{ t('footer.policies') }}</div>
        <NuxtLink :to="generateLink('/terms-and-conditions')" class="text-primary-1000">{{
          t('footer.terms')
        }}</NuxtLink>
        <NuxtLink :to="generateLink('/privacy-policy')" class="text-primary-1000">{{ t('footer.privacy') }}</NuxtLink>
        <NuxtLink :to="generateLink('/return-policy')" class="text-primary-1000">{{ t('footer.returns') }}</NuxtLink>
      </div>
    </div>

    <div class="container grid grid-cols-5 justify-center items-center pb-4 mt-4">
      <div class="col-span-2 flex flex-col space-y-4">
        <div class="text-2xl text-primary-1000 font-semibold">
          {{ t('footer.newsletter') }}
          <p v-if="showMessage" class="text-sm mt-2 -mb-2 font-bold bg-white rounded-md p-4 max-w-fit">
            {{ showMessage ? message : '' }}
          </p>
        </div>
        <form
          v-if="!showMessage"
          @submit.prevent="handleSubscribe"
          class="flex items-center justify-center w-2/3 h-12 rounded-md bg-white ltr:pr-5 rtl:pl-5"
        >
          <input
            class="w-full rounded-lg text-left text-sm py-2 px-4 flex items-center focus:outline-none rtl:text-right"
            :placeholder="t('forms.placeholders.newsletter')"
            type="email"
            required
            v-model="email"
          />
          <button class="bg-white pr-2 py-0.5" type="submit">
            <Icon name="vaadin:paperplane" class="w-5 h-5 text-primary-1000" />
          </button>
        </form>
      </div>
      <div class="col-span-2 flex flex-col space-y-4">
        <div class="text-xl text-primary-1000 font-semibold">{{ t('footer.payment_methods') }}</div>
        <div class="flex flex-wrap gap-x-2">
          <img src="~/assets/images/visa_logo.png" class="w-10 object-contain" />
          <img src="~/assets/images/mastercard_logo.png" class="w-10 object-contain" />
          <img src="~/assets/images/tabby_logo.svg" class="w-14 h-10" />
          <!-- <img src="~/assets/images/tamara_logo.png" class="w-10 object-contain" /> -->
          <img src="~/assets/images/apple_pay_logo.svg" class="w-10 object-contain" />
          <img src="~/assets/images/google_pay_logo.svg" class="w-10 object-contain" />
        </div>
      </div>
      <div class="flex flex-col gap-y-4">
        <div class="text-xl text-primary-1000 font-semibold capitalize">{{ t('footer.follow_us') }}</div>
        <div class="flex flex-row gap-x-4">
          <a :href="settings?.facebook" target="_blank">
            <Icon name="tabler:brand-facebook-filled" class="w-8 h-8 text-primary-1000 cursor-pointer" />
          </a>
          <a :href="settings?.instagram" target="_blank">
            <Icon name="tabler:brand-instagram" class="w-8 h-8 text-primary-1000 cursor-pointer" />
          </a>
          <a :href="settings?.youtube" target="_blank">
            <Icon name="tabler:brand-youtube-filled" class="w-8 h-8 text-primary-1000 cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center w-full bg-neutral-100 py-8 space-y-4">
      <div class="text-lg text-gray-400">©{{ new Date().getFullYear() }} {{ t('footer.distribution') }}</div>
      <div class="text-gray-300">
        {{ t('footer.disclaimer') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Settings } from '~/interfaces/Settings';

defineProps({
  settings: {
    type: Object as PropType<Settings>,
    required: false,
    default: () => ({} as Settings),
  },
});

const { t } = useI18n();

const email = ref('');

const menuStore = useMenuStore();
const { menuItems } = storeToRefs(menuStore);

const shopMenu = computed(() => menuItems.value.find((item) => item.id === 3));

const showMessage = ref(false);
const message = ref('');

async function handleSubscribe() {
  await useFetch('/api/newsletter/subscribe', {
    method: 'POST',
    body: {
      email: email.value,
    },
    onResponse({ response }) {
      if (response.ok) {
        showMessage.value = true;
        message.value = t('responses.newsletter.success');
      } else {
        showMessage.value = true;
        message.value = t('responses.newsletter.error');
      }
    },
  });
}
</script>

<style scoped></style>
