<template>
  <footer>
    <LayoutFooterLocationsFooter
      v-if="$route.path == generateLink('/') || $route.path == generateLink('/why-biogena')"
    />

    <div class="hidden lg:block">
      <LayoutFooterDesktopFooter :settings="settings" />
    </div>

    <div class="lg:hidden">
      <LazyLayoutFooterNewsletter />
      <LazyLayoutFooterMobileFooter />
    </div>
  </footer>
</template>

<script setup lang="ts">
const settingsStore = useSettingsStore();
const { settings } = storeToRefs(settingsStore);
</script>
